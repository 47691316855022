import { Link } from "react-router-dom";
import Logo from "../Images/Logo.svg";

function header() {
  return (
    <div className="container mx-auto w-1300 py-15 border-b border-whitedull">
      <div className="flex mx-auto justify-between items-center">
        <div className="w-100 h-100 flex justify-center">
          <img src={Logo} alt="logo" width="70" height="70"></img>
        </div>

        <div className="flex justify-between space-x-50 ">
          <Link to="privacy-policy">
            <div className="text-xl leading-6 text-gray text-center font-medium">
              Privacy Policy
            </div>
          </Link>

          <Link to="/terms-and-conditions"><div className="text-xl leading-6 text-gray text-center font-medium">
            Terms of Services
          </div></Link>
          <a href="https://www.baruzotech.com/contact_us" target="_blankx">
          <div className="text-xl leading-6 text-gray text-center font-medium">
            Contact Us
          </div>
          </a>
        </div>
      </div>
    </div>
  );
}
export default header;
