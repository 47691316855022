import React from 'react'
import Header from "./Header";
import Easymaps from "./EasyMaps";
import HowItWorks from "./HowItWorks";
import Footer from './Footer'

export default function Home(){
  return (
    <div className="fontProducts">
      <Header />
      <Easymaps />
      <HowItWorks />
      <Footer />
    </div>
  )
}
