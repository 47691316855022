import { Link } from "react-router-dom";

function footer() {
  return (
    <div className="flex-col justify-center items-center">
      <div className="flex justify-center items-center mt-160 py-20">
        <div className="border-y border-lightBlue w-1300 flex justify-between items-center px-12">
          <div className="flex justify-between items-center text-center w-361 py-36">
            <a
              href="mailto:info@baruzotech.com"
              target="_blank"
              rel="noreferrer"
              className="flex items-center text-20 leading-6 text-bluedark "
            >
              info@baruzotech.com{" "}
            </a>
            <div className="h-6 w-1 border-r-2 border-bluedark leading-6"></div>
            <a href="tel:+91 9313926540" className="Blondie">
              <div className="flex justify-start text-20 leading-6 text-bluedark">
                +91 9313926540
              </div>
            </a>
          </div>
          <div className="flex justify-between items-center space-x-20 text-20 leading-6">
            <Link to="privacy-policy">
              <div>Privacy Policy</div>
            </Link>
            <Link to="/terms-and-conditions">
              <div>Terms of Service</div>
            </Link>
            <a href="https://www.baruzotech.com/contact_us" target="_blankx">
              <div>Contact Us</div>
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center py-30 ">
        <span className="text-16 leading-19 text-carbon ">Developed by</span>
        <a href="https://www.baruzotech.com/" target="_blank ">
          <span className="text-16 leading-19 text-bluedark pl-2">
            {" "}
            Baruzotech Private Limited
          </span>
        </a>
      </div>
    </div>
  );
}
export default footer;
