import Table1 from "../Images/Table1.svg";
import Arrow1 from "../Images/Arrow 1.svg";
import Arrow2 from "../Images/Arrow 2.svg";
import Form from "../Images/Form.svg";
import Table2 from "../Images/Table2.svg";

function easyMaps() {
  return (
    <div className="flex flex-col justify-center items-center space-y-10 pt-100">
      <div className="flex justify-center items-center">
        <div className="absolute w-403 h-45 bg-blue flex justify-center">
          <div className="relative -mt-11 w-386 h-90 font-bold text-center text-74 leading-90 text-bluedark ">
            EASYMAPS
          </div>
        </div>
      </div>
      <div className="w-477 h-58 text-2xl leading-29 text-center text-graydark">
        <p>
          EasyMaps creates Google Maps{" "}
          <span className="align-sub text-sm">TM</span> url from the address
          data in the spreadsheet.
        </p>
      </div>
      <div className="pt-70">
      <a href="https://workspace.google.com/marketplace/app/easymaps/605488716859">
        <button className="bg-bluedark text-center text-20 leading-6 text-white rounded-10 px-30 py-23 ">
          Get in Google marketplace{" "}
         
        </button>
        </a>
      </div>
      <div className="flex justify-center items-center pt-90">
        <div>
          <img src={Table1} alt="sheet1"></img>
        </div>
        <div>
          <img src={Arrow1} alt="arrow1"></img>
        </div>
        <div>
          <img src={Form} alt="form"></img>
        </div>
        <div>
          <img src={Arrow2} alt="arrow2"></img>
        </div>
        <div>
          <img src={Table2} alt="sheet2"></img>
        </div>
      </div>
    </div>
  );
}
export default easyMaps;
