import React from "react";

function howItWorks() {
  return (
    <div className="flex-cols justify-center items-center py-20">
      <div className="flex flex-cols justify-center items-center pt-150">
        <div className="absolute w-505 h-45 bg-blue flex justify-center"></div>
        <div className="relative -mt-11 w-485 h-90 text-74 leading-90 text-bluedark">
          <div className="text-center">How it works?</div>
        </div>
      </div>
      <div className="flex justify-center items-center pt-70">
        <iframe className="w-730 h-419 flex justify-center items-center rounded-15 "
          src="https://www.youtube.com/embed/uD4ZitsUEbc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
export default howItWorks;
